import React, { useEffect } from "react";

import {
  currentCountryVar,
  currentUserVar,
  useQuery,
  useReactiveVar,
} from "@apollo";
import { SCREEN_GET_PROFILE_DATA } from "@queries";

// Misc Imports
import { Theme } from "@app-types";
import { Assets } from "@assets";
import { useTranslation } from "@i18n";
import { LinkedCard, Wallet } from "@models";
import { makeStyles } from "@styles";
import { navigate } from "@utils";

// Component imports
import { ProfileDisplay, ProfileLinkedCardsDisplay } from "@components";
import { Grid, Typography } from "@material";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "80%",
    maxWidth: 1400,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 35,
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  header: {
    fontSize: 42,
    fontWeight: 800,
    color: "black",
    marginBottom: 12,
  },
  subHeader: {
    color: "#393939",
    fontWeight: 400,
    fontSize: 20,
    marginBottom: 21,
  },
  displaysContainer: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: "auto",
    marginRight: "auto",
    minHeight: 630,
    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
    },
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  transactionHeader: {
    fontWeight: 800,
    fontSize: 24,
    color: "black",
  },
  loadingSpinnerContainer: {
    height: 400,
  },
  displayContainer: {
    [theme.breakpoints.up("sm")]: {
      top: 88,
      marginRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 16,
      marginRight: 0,
    },
  },
  rockEmoji: {
    width: 19,
    height: 19,
    marginLeft: 8,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}));

export type ScreenData = {
  wallet: Wallet;
  linkedCards: LinkedCard[];
};

export default function Profile() {
  const classes: any = useStyles({});
  const { t } = useTranslation("profile");

  const country = useReactiveVar(currentCountryVar);
  const user = useReactiveVar(currentUserVar);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!user) navigate("/sign-in", { replace: true });
  }, []);

  const { data } = useQuery<ScreenData>(SCREEN_GET_PROFILE_DATA, {
    fetchPolicy: "cache-only",
    variables: { id: user?.id, currency: country?.currencyCode },
  });

  const { wallet, linkedCards } = data || {};

  return !user ? null : (
    <>
      <div className={classes.root}>
        <Typography variant="h2" className={classes.header}>
          {t("myProfile")}
        </Typography>
        <Typography variant="h6" className={classes.subHeader}>
          {t("profileSubheader")}
          <img
            src={Assets.ROCK_EMOJI}
            className={classes.rockEmoji}
            alt="the-horns"
          />
        </Typography>
        <Grid
          container
          item
          direction="row"
          alignItems="flex-start"
          justify="space-between"
          lg={12}
          className={classes.displaysContainer}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={4}
            className={classes.displayContainer}
          >
            {user && wallet && <ProfileDisplay user={user} wallet={wallet} />}
          </Grid>
          <Grid
            container
            item
            justify="flex-end"
            alignItems="center"
            xs={12}
            sm={12}
            md={7}
            lg={8}
          >
            {linkedCards && (
              <ProfileLinkedCardsDisplay linkedCards={linkedCards} />
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}
